import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import FeatureOverview from "../../components/FeatureOverview";

const Features = ({location}) => (
  <DefaultLayout
    title="Time Tracking"
    description="Use our advanced search and ordering options to dice and slice your work just the way you need."
    location={location}
  >
    <Hero size="sm" title="Time Tracking" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription img="time-tracking-1.png">
        Our card workflow model already allows to signal which cards are currently being started.
        Once you opt-in to time tracking, any card that is started, will be measured automatically.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Convenience built in"
        img="time-tracking-2.png"
        imgRight
      >
        The tracking widget will be always present while tracking time. This allows you to quickly
        stop and restart and a task no matter what page you're on. We also realize that your
        everyday work may be full of little interruptions. This is why we allow you to adapt your
        time on the fly.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Manually report your time"
        img="time-tracking-3.png"
      >
        Once time-tracking is activated, you are in no way forced to make use of the real-time
        tracker exclusively. A card's history panel allows you to add and manage your time tracking
        data.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Powerful reports"
        img="time-tracking-4.png"
        imgRight
      >
        Our time tracking report allows you to dig into your team's tracked time. Here you can
        filter and sort by date, owners and projects. Export your report as csv to integrate it into
        your billing system.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
